* {
  margin: 0;
}

/* Hide the default scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Adjust as needed */
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; /* Make the scrollbar track transparent */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent; /* Make the scrollbar handle/thumb transparent */
  border: none; /* Remove border */
}

input::placeholder {
  font-style: italic;
}

textarea::placeholder {
  font-style: italic;
}

select::placeholder {
  font-style: italic;
}

body {
  /* width: 450px !important; */
  display: block !important;
  margin: auto !important;
}

html{
  background-image: url("./assets/backImage.png");
  background-position: center 280px;
  background-repeat: no-repeat;
  background-size: 200px;
}

.swal2-show {
  width: 30%!important;
  height: 300px!important;
}

.swal-wide{
  width:350px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.bottomImage {
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 50%;
}


.roundImage {
  height: 80px;
  width: 80px;
  overflow: hidden;
  border-radius: 50%;
}

.splashScreen{
  position: fixed;
  width: 100%;
  height: 100vh;
  background: white;
  top:0;
  left:0;
  z-index: 1000;
}

.progression{
  width: 180px;
  /* height: 5vh; */
}

.react-confirm-alert-overlay{
  z-index: 1000!important;
  background: rgba(0, 0, 0, 0.6)!important;
}

.alertBtn{
  border-radius: 20px!important;
  padding:5px 20px!important;
  font-size: 0.85em!important;
  font-weight: 900!important;
  box-shadow: 2px 2px 5px rgba(255,255,255,0.6),-2px -1px 5px rgba(255,255,255,0.6);
}