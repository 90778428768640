.addCustomer__section {
  /* background: linear-gradient(180deg, #1050e6 10%, transparent 0) !important; */
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-height: 100vh !important;
}

@media only screen and (max-width: 600px) {
  .addCustomer__section {
    box-shadow: none !important;
  }
}

.bottomBorder {
  border-bottom: 1px solid lightgray;
}