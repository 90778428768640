.add-template_container select, .add-template_container textarea {
    box-shadow: 0 0 5px 1px #ddd;
    resize: none;
}

.add-template_container select {
    font-size: 12px;
}

.add-template_container label {
    font-size: 16px;
    font-weight: 700;
}

.add-template_container textarea {
    height: 150px !important;
}

#formDesc::placeholder {
    font-size: 12px;
}


/* Style the label to appear as a button */
.file-input-label {
    /* display: inline-block; */
    padding: 7px 15px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    /* background-color: #fff; */
    color:#000;
    opacity:1
}

/* Hide the actual file input */
.file-input {
    display: none;
}