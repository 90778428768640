.appointment__section {
  /* background: linear-gradient(180deg, #1050e6 10%, transparent 0) !important; */
  /* height: 100vh !important; */
  /* overflow-y: overlay !important; */
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-height: 100vh !important;
}

.appointment__section::-webkit-scrollbar {
  display: block;
}

.appointment__content__paraDark {
  color: #000000;
  font-size: 14px;
}

.appointment__content__paraLight {
  color: #737373;
  font-size: 14px;
}

.appointment__content__row {
  border-bottom: 1px solid;
}

.plus__button {
  position: fixed !important;
  bottom: 20px !important;
  left: 600px !important;
}


.appointement_plus__button {
  position: sticky !important;
  bottom: 3% !important;
  left:61%!important;
}
@media only screen and (max-width: 600px) {
  .appointment__section {
    box-shadow: none !important;
  }
  .plus__button {
    left: 360px !important;
  }
}
