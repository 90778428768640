.vendor-profile_container{
    background: #fff;
    padding: 80px 20px 20px 20px;
    position: relative;
    overflow: visible!important;
}

.vendor-profile_container .addimage_container{
    position: absolute!important;
    left: 50%;
    transform: translateX(-50%);
    top: -30px;
    z-index: 100;
    opacity: 1;
    color: #c4c4c4;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vendor-profile_container .addimage_container img{
    /* width: 50px; */
    box-shadow: 0 0 10px 2px rgba(0,0,0,.4);
    /* border-radius: 50%; */
}

.vendor-profile input{
    
}

.vendor-profile .shop-timing label{
    font-size: 16px!important;
}

.vendor-profile_container .addimage_container .roundImage {
    box-shadow: 0 0px 10px 0px rgba(0,0,0,.4);
}

.header_options {
    position: relative;
    top: 40px;
}

.data-inputs_container .input_container select{
    -webkit-appearance: none;
    border: none;
    outline: none;
    flex: 0 0 90%;
    max-width: 90%;
    margin: 20px 10px;
}

.input_container{
    border-bottom: 1px solid #ebebeb;
    align-items: center;
}

.show-input_container{
    border-bottom: 1px solid #ebebeb;
    align-items: center;
}

.show-input_container input{
    border: none;
    outline: none;
    background: none;
    padding: 5px 10px;
    flex: 0 0 90%;
    max-width: 90%;
}

.input_container input{
    border: none;
    outline: none;
    background: none;
    padding: 20px 10px;
    flex: 0 0 90%;
    max-width: 90%;
}

.filled-btn{
    border: none;
    outline: none;
    background: rgb(16,80,230);
    width: 100%;
    height: 50px;
    color: #fff;
    text-align: center;
    border-radius: 30px;
}

.data-inputs_container .input_container img{
    flex: 0 0 5%;
    max-width: 5%;
    opacity: .7;
}

.data-inputs_container .input_container:last-child img{
    flex: 0 0 4%;
    max-width: 4%;
}