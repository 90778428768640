.profile__section {
  /* background: linear-gradient(180deg, #1050e6 10%, transparent 0) !important; */
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-height: 100vh !important;
}

h4 {
  color: #3d3d3d;
}

.title_get{
  font-size: 1.2em;
  white-space: nowrap;
}

.card {
  /* border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important; */
  border: none !important;
  max-height: 100vh !important;
  overflow: hidden !important;
}

.profile__content{
  background: #1050e6;
}

.profile__content__para {
  color: #737373;
  font-size: 12px;
}

.profile__content__right {
  color: #3d3d3d;
}

.profile__content__left {
  color: #737373;
}

.profile__content_col {
  border-bottom: 1px solid #ddd;
}

.editIcon {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .profile__section {
    box-shadow: none !important;
  }
}