.singup_section {
  /* background: linear-gradient(180deg, #1050e6 25%, transparent 0) !important; */
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  overflow: hidden !important;
}

@media only screen and (max-width: 600px) {
  .singup_section {
    box-shadow: none !important;
    overflow: scroll;
  }
}

.card {
  /* border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important; */
  border: none !important;
  max-height: 100vh !important;
  overflow: hidden !important;
}

label {
  color: #c4c4c4 !important;
  font-size: 12px !important;
}

.login__span {
  background: transparent !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
}

.login__input {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  font-size: 14px !important;
}
.login__input:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.login__img {
  height: 20px !important;
}

.login__small {
  color: #737373;
  float: right;
}

.sign__submit__row {
  display: flex !important;
  place-items: center !important;
  justify-content: center !important;
  align-items: center !important;
  height: 50vh !important;
}

@media only screen and (max-width: 600px) {
  .sign__submit__row {
    height: auto !important;
  }
}

.login__submit_button {
  background: #1050e6 !important;
  width: 100% !important;
  border-radius: 22px !important;
  color: #f7f7f7 !important;
  font-weight: bold !important;
  height: 44px !important;
}

.login__submit__text {
  color: #3d3d3d !important;
  text-align: center !important;
  font-size: 14px !important;
  outline-width: 0 !important;
}

.login__submit__text:focus {
  box-shadow: none !important;
}
