.addAppointment__section {
  /* background: linear-gradient(180deg, #1050e6 10%, transparent 0) !important; */
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-height: 100vh !important;
}

@media only screen and (max-width: 600px) {
  .addAppointment__section {
    box-shadow: none !important;
  }
}

.addAppointment__select {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #ced4da;
  background: transparent;
  padding: 8px 0px;
  font-size: 14px !important;
  color: #c4c4c4;
}

.addAppointment__button {
  background: #1050e6 !important;
  box-shadow: 0px 4px 12px rgba(16, 80, 230, 0.2) !important;
  border-radius: 22px !important;
  border: none !important;
  color: #f7f7f7 !important;
  width: 100%;
  padding: 10px;
}


.close_add_cust_btn__button {
  background: red !important;
  box-shadow: 0px 4px 12px rgba(16, 80, 230, 0.2) !important;
  border-radius: 22px !important;
  border: none !important;
  color: #f7f7f7 !important;
  width: 100%;
  padding: 10px;
}