.custom-table_container{
    width: 100%;
    overflow-y: scroll;
    color: #4f4f4f;
    /* height: calc(100vh - 54px); */
    overflow-y: auto;
}

.custom-table{
    border-collapse: separate;
    border-spacing: 4px;
}

.custom-table th,.custom-table td{
    text-align: center;
    margin: 10px;
    background: #fff;
    padding: 8px 16px;
    box-shadow: 0 0 10px 1px #ddd;
    font-size: 12px;
}

.custom-table th{
    position: sticky;
    top: 0;
    margin-top: 0!important;
}

.success-data{
    color: green;
}

.pending-data{
    color: gold;
}