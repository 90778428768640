.add__section {
  /* background: linear-gradient(180deg, #1050e6 8%, transparent 0) !important; */
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-height: 100vh !important;
}

.top__navbar {
  background: #1050e6;
  position: sticky !important;
  top: 0;
  z-index: 100;
}
.navbar-toggler {
  border: none !important;
  display: block !important;
  font-size: 14px !important;
}
.navbar-toggler:focus {
  outline: none !important;
  background: transparent !important;
}

.navbar img {
  /* height: 25px !important; */
}

.add__content__card {
  background: #fff;
  border-radius: 20px !important;
  /* cursor: pointer; */
  color: #3d3d3d;
  font-weight: 500;
  box-shadow: 0px 4px 16px rgba(16, 80, 230, 0.35);
}

.navbar-nav {
  flex-direction: row !important;
}

.navbar-inverse {
  /* background: linear-gradient(90.21deg, #ef3f64 0.34%, #ef3f64 99.68%); */
  padding: 0px 10px;
  box-shadow: 0 0 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  /* border-radius: 20px 20px 0px 0px; */
  z-index: 0;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .navbar-inverse {
    width: 450px !important;
    display: block !important;
    margin: auto !important;
  }
}

/* ----------- iPad Pro ----------- */

/* Portrait and Landscape */

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .navbar-inverse {
    width: 450px !important;
    display: block !important;
    margin: auto !important;
  }
}

@media screen and (min-width: 1024px) and (max-height: 1310px) {
  .navbar-inverse {
    width: 450px !important;
    display: block !important;
    margin: auto !important;
  }
}

@media only screen and (min-device-width: 800px) {
  .navbar-inverse {
    width: 450px !important;
    display: block !important;
    margin: auto !important;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard_section {
    box-shadow: none !important;
  }
}
