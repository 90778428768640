.customer__section {
  /* background: linear-gradient(180deg, #1050e6 10%, transparent 0) !important; */
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  min-height: 100vh !important;
}

.search-bar_container{
  position: sticky!important;
  top: 54px;
  z-index: 10;
  background: #fff;
}

.customer__section::-webkit-scrollbar {
  display: none;
}

.customer__paraDark {
  color: #3d3d3d;
  font-weight: bold;
}

.customer__paraLight {
  color: #737373;
}

.customer__card {
  background: #fff;
  border-radius: 20px !important;
  box-shadow: 0px 4px 16px rgba(16, 80, 230, 0.20);
}

.customer__cardBody {
  padding: 1rem !important;
}

.customer_plus__button {
  position: sticky !important;
  bottom: 3% !important;
  left:80%!important;
}

@media only screen and (max-width: 600px) {
  .customer__section {
    box-shadow: none !important;
  }
}

@media (min-width:1400px){
  .customer_plus__button {
    right:48%!important;
  }
}