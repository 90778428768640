.estimate__section {
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  position: relative;
}

@media only screen and (max-width: 600px) {
  .estimate__section {
    box-shadow: none !important;
  }
}

.estimate__dark {
  color: #c4c4c4;
  font-size: 8px;
}

.estimate__light {
  color: #737373;
  font-size: 14px;
}

.estimate__col {
  border-bottom: 1px solid;
}

.estimate__card {
  background: #ffffff;
  box-shadow: 0px -2px 16px rgba(16, 80, 230, 0.2);
  border-radius: 30px 30px 0px 0px;
  min-height: 340px;
}
.estimate__input {
  width: 100%;
  border: 2px solid #ebebeb;
  outline: none;
  border-radius: 5px;
  padding: 5px;
}

.estimate__addIssue {
  color: #1050e6 !important;
  box-shadow: 0px 4px 16px rgba(16, 80, 230, 0.2);
  border: 1px solid #1050e6 !important;
  border-radius: 50px !important;
  padding: 8px 20px !important;
}

.estimate__save {
  color: #f7f7f7 !important;
  box-shadow: 0px 4px 16px rgba(16, 80, 230, 0.08);
  border: 1px solid #1050e6 !important;
  border-radius: 50px !important;
  padding: 8px 40px !important;
  background: #1050e6 !important;
}

.generate-invoice_container{
  position:absolute;
  left:0;
  bottom:0;
  width:100%;

}

.generate-invoice-data{
  color: #4f4f4f;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 10px 20px!important;
}

.generate-invoice-data .data{
  margin-bottom: 0;
}

.generate-invoice-btn{
  border: none;
  outline: none;
  background:#1050e6;
  color: #fff;
  width:100%;
  padding:10px 0;
  color:"#fff"
}
