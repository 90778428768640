.bill-page {
    width: 100%;
    margin: 0 auto !important;
    position: relative;
    top: 0;
    left: 0;
}

.company-logo {
    width: 80px;
    height: 100%;
}

.compony-details h5 {
    font-size: 8px;
}

