.message__section {
  height: 100vh !important;
  overflow-y: overlay !important;
  color: #f7f7f7 !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.message__section::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .message__section {
    box-shadow: none !important;
  }
}

.message__select {
  border-bottom: 1px solid #f5f5f5 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  background: transparent;
  outline: 0;
  width: 80%;
}

.message__content__text {
  color: #737373;
}

.message__content__card {
  background: #fff;
  border-radius: 10px !important;
  box-shadow: 0px 4px 16px rgba(16, 80, 230, 0.2);
}

.message__content__small {
  font-size: 10px;
  color: #3d3d3d;
}
.message__content__p {
  font-size: 12px;
  color: #737373;
}

.message__button {
  box-shadow: 0px 2px 8px rgba(16, 80, 230, 0.18);
  color: #1050e6;
  border-radius: 50px !important;
  padding: 4px 20px !important;
}
